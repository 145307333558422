<template>
    <v-container>
        <v-card width="100%" flat rounded class="summary-bar">
            <v-card-text> 
                <v-row class="summary-bar-wrapper d-flex align-center" role="region" :class=" $vuetify.breakpoint.mdAndUp ? 'flex-wrap' : 'flex-wrap-reverse'" aria-label="Summary" >
                    <div v-if="$vuetify.breakpoint.mdAndUp || pets.length === 1" class="d-flex flex-row align-center pa-0 flex-grow-1">
                        <div class="summary-bar-pet d-flex flex-row align-center pa-0" v-for="(pet,index) in pets" :key="pet.id" >
                            <Pet :key="pet.id" isPetSelectable @switchPet="switchPet" :petDetails=pet :petIndex="index" :isCurrent="!isFamilyPlan" :isChallenger="true" :isSimpleLayout="false" :isEditable="true" :ref="'pet-'+index" @updatePricing="updatePricing"></Pet>                        
                        </div>
                    </div>
                    <div class="d-flex flex-row align-center justify-center" style="width: 100%;" v-else>
                        <v-carousel
                            id="mobile-pet-selector"
                            :continuous="false"
                            :cycle="false"
                            :show-arrows="true"
                            height="120"
                            hide-delimiters
                            :value="currentPetIndex"
                        >
                            <v-carousel-item v-for="(pet,index) in pets" :key="pet.id"> 
                                <v-sheet class="d-flex justify-center align-center" height="100%">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <Pet :key="pet.id" isPetSelectable @switchPet="switchPet" :petDetails=pet :petIndex="index" :isCurrent="!isFamilyPlan" :isChallenger="true" :isSimpleLayout="false" :isEditable="true" :ref="'pet-'+index" @updatePricing="updatePricing"></Pet>                        
                                    </v-row>
                                </v-sheet>
                            </v-carousel-item>
                        </v-carousel>
                    </div>
                    <v-spacer></v-spacer>
                    <span class="d-sr-only" aria-live="polite">{{announcementText}}</span>
                    <div class="ml-auto align-self-center pa-1 mr-2 price-total-wrapper" :class="$vuetify.breakpoint.mdAndUp ? '':'mr-auto'">
                        <div class="d-flex justify-start align-start" v-if="pets.length > 1 && !isFamilyPlan && offerFamilyPlan(pets, partner.isAgeRestrictionExempted)">
                            <v-btn x-large class="btn-tight btn-lower-case" aria-describedby="FamilyPlanChanges" text ref="showIndividualPlanBtn" color="primary" @click.stop="showPlanTypeDialog(true)">  <v-icon small dark class="btn-pencil-circle"> mdi-pencil </v-icon>Individual Plans </v-btn>
                        </div>
                        <div class="d-flex justify-start align-start" v-if="pets.length > 1 && isFamilyPlan">
                            <v-btn x-large class="btn-tight btn-lower-case" aria-describedby="IndividualPlanChanges" text ref="showFamilyPlanBtn" color="primary" @click.stop="showPlanTypeDialog(true)"> <v-icon small dark class="btn-pencil-circle"> mdi-pencil </v-icon> Family Plan</v-btn> 
                        </div>
                        <div class="mt-1 d-flex justify-start align-start" v-if="isFamilyPlan">
                            <div class="price-value lg">
                                <span class="currency font-weight-bold">{{calculateFamilyPlanTotal().charAt(0)}}</span>
                                <span class="units font-weight-bold">{{(calculateFamilyPlanTotal().slice(1)).split('.')[0]}}</span>
                                <span class="cents font-weight-bold">
                                    .{{(calculateFamilyPlanTotal().slice(1)).split('.')[1]}}
                                    <span class="payment-frequency d-flex flex-column align-start pl-2">/mo</span>
                                </span>
                            </div>
                        </div>
                        <div class="mt-1 d-flex justify-start align-start" v-else>
                            <div v-if="Object.keys(plans).length > 0">
                                <div class="text-center">
                                    <div class="price-value lg">
                                        <span class="currency font-weight-bold">{{ getPriceSplit( calculateTotals() )[0] }}</span>
                                        <span class="units font-weight-bold">{{ getPriceSplit( calculateTotals() )[1]}}</span>
                                        <span class="cents font-weight-bold">
                                            {{ getPriceSplit( calculateTotals() )[2]}}
                                            <span class="payment-frequency d-flex flex-column align-start pl-2">/mo</span>
                                        </span>
                                    </div>

                                    <!-- ${{calculateTotals()}} -->
                                </div>
                            </div>
                        </div>
                        <!-- <span class="ml_blue--text d-flex flex-row justify-start align-start"> <v-icon class="ml_green--text mt-n1"> mdi-check </v-icon>{{ this.getDiscounstApplied() }}</span> -->
                        <div class="d-flex flex-row">
                        <span class="ml_blue--text d-flex flex-row justify-center align-center" style="font-size: 10px; line-height: 10px;"> <v-icon class="ml_green--text mt-n1"> mdi-check </v-icon>{{ this.getDiscounstApplied() }} </span>
                        <v-menu v-model="showDiscountsApplied" bottom :offset-y="offsetTipY"  :close-on-content-click="closeTipOnClick">
                            <template v-slot:activator="{on, attrs }">
                                <v-btn x-small v-bind="attrs" icon v-on="on"> <v-icon>mdi-chevron-down</v-icon></v-btn>
                            </template>
                            <v-card>
                                <v-card-title>Discount{{ getSavedDiscountsData().length > 1 ? 's':'' }} Applied:</v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="py-3"  v-for="discount in getSavedDiscountsData()" :key="discount.discountId">
                                    <v-icon class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon> {{ discount.name }}
                                </v-card-text>
                            </v-card>
                        </v-menu>
                        </div>
                        
                    </div>
                    <div class="align-self-center d-flex flex-row" v-if="$vuetify.breakpoint.mdAndUp">
                        <div>
                            <v-divider role="none" vertical></v-divider>
                        </div>
                        <div class="d-flex flex-column align-center ml-3">
                            <v-btn block x-large color="ml_purple" tabindex="0" @click="goToCheckout()">
                                Next Step
                                <v-icon small>mdi-play</v-icon>
                            </v-btn>
                            <p class="pt-3 mb-0 support-phone">Or call: 
                                <a aria-labelledby="partner-phone-contact" class="ml_darkblue--text support-phone" :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()">  {{this.getNumbertoAlphanumberic(this.getPartnerContactInfo())}}</a>
                            </p>
                        </div>
                    </div>
                    <div>
                        <editCreatePetDialog @closeDialog="showAddPetDialog"
                                             @hasFinished="AddPetPetDialogFormFinished"
                                             :dialog="newPetDialog"
                                             :isEdit="false">
                        </editCreatePetDialog>
                        <PlanTypeDialog 
                            @closeDialog="showPlanTypeDialog"
                            :dialog="planTypeDialog"
                            @handleShowFamilyPlanClick="handleShowFamilyPlanClick"
                            @handleShowIndividualPlanClick="handleShowIndividualPlanClick"
                        >
                        </PlanTypeDialog>
                    </div>
                </v-row>

            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    import Pet from '../../challenger/shared/pet.vue';
    import editCreatePetDialog from '../../challenger/shared/editCreatePetDialog.vue';
    import SummaryViewPanel from '../../shared/SummaryViewPanel.vue';
    import PlanTypeDialog from './PlanTypeDialog.vue';

    import SummaryBar from '../../global/js/SummaryBar.js';
    export default {
        name: 'summaryBar',
        mixins: [SummaryBar],
        components: {
            Pet, editCreatePetDialog, SummaryViewPanel, PlanTypeDialog
        },
        data: function () {
            return {
                planTypeDialog: false,
                closeTipOnClick: true,
                offsetTipY: true,
                showDiscountsApplied: false
            };
        },
        computed: {
            availableDiscounts: function () {
                return this.$store.state.discounts.options;
            },
            savedDiscounts: function () {
                return this.$store.state.discounts.selection;
            },
            partner: function() {
                return this.$store.getters.partner;
            },
        },
        props: {            
            isFamilyPlan: {
                type: Boolean,
                default: false,
            },
            pets: {
                type: Array
            },
            plans: {
                type: Array
            },
            headers: {
                type: Array
            }
        },
        methods: {
            handleShowIndividualPlanClick() {
                this.$emit("handleShowIndividualPlanClick");
            },
            handleShowFamilyPlanClick() {
                this.$emit("handleShowFamilyPlanClick");
            },
            goToCheckout() {
                this.$emit("goToCheckout");
            },
            trackAdobeEventPhone() {
                let ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: this.getPartnerContactInfo(),
                    }
                };
                digitalData.eventTrack('linkClick', ldo);
            }, 
            showPlanTypeDialog(status) {
                if (!status) {
                    // this.$store.commit("setCurrentPetIndex", 0);
                } else {
                    // let ldo = {
                    // eventName: 'linkClick',
                    //     attributes: {
                    //         application: 'MLPI',
                    //         form: 'Pet Insurance Quote',
                    //         linkName: 'Add Pet',
                    //         leadId: this.leadId,
                    //         stepName: 'Plan Selection'
                    //     }
                    // };
                    // digitalData.eventTrack('linkClick', ldo);
                }

                this.planTypeDialog = status;
            },
            getSavedDiscountsData(){
                return this.availableDiscounts.filter( discount => {
                    return this.savedDiscounts.includes(discount.discountId);
                })
            }
        }
    }
</script>