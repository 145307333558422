<template>
    <!-- Pet Title -->
    <v-card-title class="mb-6" v-if="!isFamilyPlan">
        <v-row class="d-flex flex-wrap  align-center justify-center" style="width: 100%;">
            <v-col cols="12" class="text-center" > 
                <h2 class="primary--text text-uppercase pricing-title-h2 pt-14" v-html="renderPlansSelectionSectionTitle"></h2>
            </v-col>
            <v-col cols="12" class="flex-grow-1 d-flex align-center justify-center py-0">
                <PetView                     
                    :aria-label="getCurrentPetName() + 'Policy.'" 
                    :petDetails="pets[currentPetIndex]" 
                    :isCurrent="false" 
                    :isSimpleLayout="true" 
                    :isChallenger="false" 
                    :showDetails="true" 
                    :isEditable="false"
                    v-if="pets.length === 1"
                ></PetView>
                <span v-else>
                    One of {{ this.getNumberToText(pets.length)}} Individual Plans
                </span>
            </v-col>
        </v-row>
    </v-card-title>
    <!-- Family Title -->
    <v-card-title class="mb-6" v-else>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="12" class="text-center"> 
                <h2 class="primary--text text-uppercase pricing-title-h2 pt-14" v-html="renderPlansSelectionSectionTitleFamilyPlan"></h2>
            </v-col>
            <v-col cols="12" class="flex-grow-1 d-flex align-center justify-center py-0">
                <div class="pet-avatar single-pet-view">
                    <ul class="pet-details horizontal-list body-1 flex-wrap align-center justify-center flex-row ml_darkergray--text">
                        <li v-for="pet in pets" :key="pet.id"> {{pet.name}}</li>
                    </ul>
                </div>
            </v-col>
        </v-row>
    </v-card-title>
</template>
<script>
    import PetView from "../challenger/shared/pet.vue";
    export default {
        components: { PetView },
        computed: {
            pets() {
                return this.$store.getters.pets;
            },
            isFamilyPlan: function () {
                return this.$store.getters.isFamilyPlan;
            },
            currentPetIndex() {
                return this.$store.getters.currentPetIndex;
            },
            defaultDynamicContent() {
                return this.$store.getters.getDefaultDynamicContent.DynamicJson;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            },
            renderPlansSelectionSectionTitle() {
                return this.dynamicContent &&  this.dynamicContent.plansSelectionSectionTitle
                    ? this.interpolateContent(this, this.dynamicContent.plansSelectionSectionTitle) 
                    : this.interpolateContent(this, this.defaultDynamicContent.plansSelectionSectionTitle);
            },
            renderPlansSelectionSectionTitleFamilyPlan() {
                return this.dynamicContent &&  this.dynamicContent.plansSelectionSectionTitleFamilyPlan
                    ? this.interpolateContent(this, this.dynamicContent.plansSelectionSectionTitleFamilyPlan) 
                    : this.interpolateContent(this, this.defaultDynamicContent.plansSelectionSectionTitleFamilyPlan);
            }
        },
        methods: {
            getCurrentPetName() {
                return this.pets[this.currentPetIndex]?.name || "";
            }
        }
    }
</script>