<template> 
    <v-card class="v-card-form" flat>
        <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'pt-5 pb-3':'pt-16 pb-7'"  class="text-center px-0 ">
            <h1>Choose a plan that's right for you</h1>
        </v-card-text>
        <v-card-text class="px-0 pb-0" v-if="pets.length > 1 && offerFamilyPlan(pets, partner.isAgeRestrictionExempted)">
            <v-card outlined class="left-outline-panel">
                <v-card-text class="d-flex flex-row justify-space-between">
                    <v-row class="d-flex flex-row align-start">
                        <v-col cols="12" class="d-flex flex-column">
                            <div class="d-flex justify-space-between align-center">
                                <div class="mr-2" v-show="$vuetify.breakpoint.mdAndUp">
                                    <v-img
                                        alt="Pet Family Plan"
                                        :src="require('@/assets/svg/icons-family-plan-banner.svg')"
                                        class="shrink"
                                        contain
                                        width="auto"
                                        max-width="150"
                                        max-height="150"
                                    />
                                </div>
                                <div>
                                    <h2>Switch to a family plan?</h2>
                                    <span>Combine {{ getPetsNamesList() }} into a shared coverage plan </span>
                                    <div class="d-flex justify-start my-4">
                                        <v-btn-toggle
                                            v-model="isFamilyPlan"
                                            class="d-flex"
                                            @change="updatePlan"
                                            rounded
                                            mandatory
                                        >
                                                <v-btn :value="false">Individual</v-btn>
                                                <v-btn :value="true">Family</v-btn>
                                        </v-btn-toggle>
                                    </div>
                                    <div>
                                        <p class="caption mb-1">A <b>Family Plan</b> groups all your pets under one plan with the same benefits.</p>
                                        <p class="caption mb-0"><b>Individual Plans</b> allow you to have individual plans for each pet.</p>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
        <v-card-text  class="px-0 pb-0">
            <!-- Current Pet Index{{ currentPetIndex }} -->
            <!-- {{  panelsExpanded }} -->
            <v-expansion-panels class="pet-panels" ref="expansionPanels" v-model="panelsExpanded" v-if="!isFamilyPlan">
                
                <v-expansion-panel v-for="(pet, index) in pets" :key="pet.id" :value="index" class="left-outline-panel mb-2">
                    <v-expansion-panel-header class="d-flex align-start flex-row flex-nowrap" @click="handlePanelClick(index)">
                        <v-row class="d-flex flex-column align-start">
                            <v-col class="pet-header d-flex flex-row flex-grow-1 justify-space-between align-center flex-nowrap">
                                <v-row class="d-flex flex-row align-start justify-space-between">
                                    <v-col cols="8">
                                        <PetComponent
                                            :petDetails="pet" :petIndex="index" :isEditable="true" :showingFamilyPlan="false" @AddPetPetDialogFormFinished="AddPetPetDialogFormFinished"
                                        ></PetComponent>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" :class="$vuetify.breakpoint.smAndDown ? 'multi-xs':'multi-sm'" class="price-value pl-0 equal-size d-flex flex-column justify-end" aria-live="polite" :key="forceUpdateKey">
                                        <span>
                                            <span class="currency font-weight-bold">{{ getPriceSplit( getMonthlyPremium(index) )[0] }}</span>
                                            <span class="units font-weight-bold">{{ getPriceSplit( getMonthlyPremium(index) )[1] }}</span>
                                            <span class="cents font-weight-bold">{{ getPriceSplit( getMonthlyPremium(index) )[2] }}</span>
                                        </span>
                                        <span class="payment-frequency d-flex flex-column align-self-end pl-2">monthly</span>

                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="pet-action-row">
                                <v-divider></v-divider>
                                <v-btn small block text class="d-flex btn-expand-wrap justify-space-between btn-lower-case px-0 mt-2"> 
                                    <span :class="$vuetify.breakpoint.smAndDown ? 'body-2': 'body-1'" class="font-weight-medium text-wrap longNameClamp2">Customize Plan for {{ pet.name }}</span>
                                    <v-sheet class="icon-sheet"  width="33" height="33">
                                        <v-icon large v-if="panelsExpanded === index">mdi-menu-up</v-icon>
                                        <v-icon large v-else>mdi-menu-down</v-icon>
                                    </v-sheet>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <!-- Pricing Tabs Section -->
                        <span ref="pricingSectionHeading" id="pricingSectionHeading" class="d-sr-only" tabindex="-1" role="heading" aria-level="3" >Pricing Plans</span>
                        <Pricing 
                            ref="pricingCards"
                            :currentPet="pet"
                            :currentPetIndex="index"
                            :announcePricingSummary="announcePricingSummary"
                            :isCustomPlanActive="isCustomPlanActive"
                            @renderSummaryBar="renderSummaryBar" 
                            @renderPrincingComponent="renderPricingComponent" 
                            @setIsCustomPlanActive="setIsCustomPlanActive" 
                            @planChange="planChange(index)"
                            :key="pricingKey"
                        />

                        <DotLoader :loading="loadingNewPrices" :onLoadingComplete="loadingCompleteController" />

                        <!-- Customized Section -->
                        <Customize 
                            ref="customize" 
                            :currentPetIndex="index"
                            @renderSummaryBar="renderSummaryBar" 
                            @deselectTab="deselectTab(index)" 
                            @selectHighDeductibleTab="selectHighDeductibleTab(index)"
                        ></Customize>

                        <v-container v-if="showWellnessSteteRestricted(partner.id, owner.state)">
                            <v-row no-gutters class="d-flex flex-row" >
                                <v-col cols="auto">
                                    <v-icon class="mr-2 mt-n2 info--text" large>mdi-information-slab-circle</v-icon>
                                </v-col>
                                <v-col>
                                    <h4>
                                        {{ 
                                            dynamicContent && dynamicContent.plansWellnessInformationTitle 
                                                ? dynamicContent.plansWellnessInformationTitle 
                                                : defaultDynamicContent.plansWellnessInformationTitle 
                                        }}
                                    </h4>
                                    <div class="mt-2" v-html="dynamicContent && dynamicContent.plansWellnessInformationBlockHtml ? dynamicContent.plansWellnessInformationBlockHtml : defaultDynamicContent.plansWellnessInformationBlockHtml">
                                    </div>
                                    <ul class="pl-0 list-no-buttlets" v-if="dynamicContent && dynamicContent.plansWellnessInformationList.length">
                                        <li v-for="item in dynamicContent.plansWellnessInformationList">
                                            <v-icon small class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon>
                                            {{ item }}
                                        </li>
                                    </ul>
                                    <ul class="pl-0 list-no-buttlets" v-else>
                                        <li v-for="item in defaultDynamicContent.plansWellnessInformationList">
                                            <v-icon small class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon>
                                            {{ item }}
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels class="pet-panels" ref="expansionPanels" v-model="panelExpanded" mandatory v-if="isFamilyPlan">
                <v-expansion-panel class="left-outline-panel mb-2">
                    <v-expansion-panel-header class="d-flex align-start flex-column flex-nowrap pr-0">

                        <v-container>
                        <v-row class="d-flex flex-column align-start" style="width: 100%;">
                            <v-col class="pet-header d-flex flex-row justify-space-between align-center flex-nowrap pb-0">
                                <v-row class="d-flex flex-row align-start justify-space-between">
                                    <v-col cols="7" md="12">
                                        <h1 v-if="$vuetify.breakpoint.smAndDown" class="ml_darkergray--text mb-2"> Family Plan </h1>
                                        <h1 v-else class="ml_darkergray--text mb-2"> Family Plan </h1>
                                    </v-col>
                                    <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
                                    <v-col cols="auto" v-if="$vuetify.breakpoint.smAndDown">
                                        <div :class="$vuetify.breakpoint.smAndDown ? 'multi-xs':'multi-sm'" class="price-value pl-0 equal-size d-flex flex-column justify-end" aria-live="polite" v-if="isFamilyPlan">
                                            <span>
                                                <span class="currency font-weight-bold">{{ calculateFamilyPlanTotal().charAt(0) }}</span>
                                                <span class="units font-weight-bold">{{ (calculateFamilyPlanTotal().slice(1)).split('.')[0] }}</span>
                                                <span class="cents font-weight-bold">.{{ (calculateFamilyPlanTotal().slice(1)).split('.')[1]}}</span>
                                            </span>
                                            <span class="payment-frequency d-flex flex-column align-self-end pl-2">monthly</span>

                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        
                        <v-row class="px-0" style="width: 100%;">
                            <v-col cols="12" class="py-0">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <div :class="$vuetify.breakpoint.smAndDown ? 'body-2':'body-1'" class=" ml_lightgray--text text--darken-4 font-weight-bold">Your shared coverage plan for {{ getPetsNamesList() }} </div>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <!-- <v-card flat class="pa-0 mt-6">
                            <v-card-title class="d-flex align-start flex-column px-0 pb-1">
                                <v-row class="d-flex flex-row justify-space-between">
                                    <v-col cols="8">
                                        <h2 class="ml_darkergray--text mb-2">Your Family Plan </h2>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto">
                                        <div :class="$vuetify.breakpoint.smAndDown ? 'multi-xs':'multi-sm'" class="price-value pl-0 equal-size d-flex flex-column justify-end" aria-live="polite" v-if="isFamilyPlan">
                                            <span>
                                                <span class="currency font-weight-bold">{{ calculateFamilyPlanTotal().charAt(0) }}</span>
                                                <span class="units font-weight-bold">{{ (calculateFamilyPlanTotal().slice(1)).split('.')[0] }}</span>
                                                <span class="cents font-weight-bold">.{{ (calculateFamilyPlanTotal().slice(1)).split('.')[1]}}</span>
                                            </span>
                                            <span class="payment-frequency d-flex flex-column align-self-end pl-2">monthly</span>

                                        </div>
                                    </v-col>
                                </v-row>
                                
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-subtitle class="px-0">
                                <div class="body-2 font-weight-bold">Your shared coverage plan for {{ getPetsNamesList() }} </div>
                            </v-card-subtitle>
                        </v-card> -->
                        <v-card flat class=" pa-0 d-flex flex-column align-start">
                            <v-card-text class="px-0 pb-2">
                                <div v-for="(pet, index) in pets" class="pet-header d-flex flex-row flex-grow-1 justify-space-between align-center mb-4">
                                    <PetComponent
                                        :petDetails="pet" :petIndex="index" :isEditable="true" :showingFamilyPlan="true" @AddPetPetDialogFormFinished="AddPetPetDialogFormFinished"
                                    ></PetComponent>
                                </div>
                                <v-divider></v-divider>
                            </v-card-text>
                        </v-card>
                        <!-- Pricing Tabs Section -->
                        <span ref="pricingSectionHeading" id="pricingSectionHeading" class="d-sr-only" tabindex="-1" role="heading" aria-level="3" >Pricing Plans</span>
                        <Pricing 
                            ref="pricingCardsFamily"
                            :currentPet="null"
                            :currentPetIndex="0"
                            :announcePricingSummary="announcePricingSummary"
                            :isCustomPlanActive="isCustomPlanActive"
                            @renderSummaryBar="renderSummaryBar" 
                            @renderPrincingComponent="renderPricingComponent" 
                            @setIsCustomPlanActive="setIsCustomPlanActive" 
                            @planChange="planChange(0)"
                            :key="pricingKey"
                        />

                        <DotLoader :loading="loadingNewPrices" :onLoadingComplete="loadingCompleteController" />

                        <!-- Customized Section -->
                        <Customize 
                            ref="customizeFamily" 
                            :currentPetIndex="0"
                            @renderSummaryBar="renderSummaryBar" 
                            @deselectTab="deselectTab(0)" 
                            @selectHighDeductibleTab="selectHighDeductibleTab(0)"
                        ></Customize>

                        <v-container v-if="showWellnessSteteRestricted(partner.id, owner.state)">
                            <v-row no-gutters class="d-flex flex-row" >
                                <v-col cols="auto">
                                    <v-icon class="mr-2 mt-n2 info--text" large>mdi-information-slab-circle</v-icon>
                                </v-col>
                                <v-col>
                                    <h4>
                                        {{ 
                                            dynamicContent && dynamicContent.plansWellnessInformationTitle 
                                                ? dynamicContent.plansWellnessInformationTitle 
                                                : defaultDynamicContent.plansWellnessInformationTitle 
                                        }}
                                    </h4>
                                    <div class="mt-2" v-html="dynamicContent && dynamicContent.plansWellnessInformationBlockHtml ? dynamicContent.plansWellnessInformationBlockHtml : defaultDynamicContent.plansWellnessInformationBlockHtml">
                                    </div>
                                    <ul class="pl-0 list-no-buttlets" v-if="dynamicContent && dynamicContent.plansWellnessInformationList.length">
                                        <li v-for="item in dynamicContent.plansWellnessInformationList">
                                            <v-icon small class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon>
                                            {{ item }}
                                        </li>
                                    </ul>
                                    <ul class="pl-0 list-no-buttlets" v-else>
                                        <li v-for="item in defaultDynamicContent.plansWellnessInformationList">
                                            <v-icon small class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon>
                                            {{ item }}
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        <!-- <v-col cols="12">
            {{ this.pets }}
        </v-col> -->
        <v-card-text class="px-0 pb-0" v-if="Object.keys(pets).length < 3 && Object.keys(pets).length !== maxPetView">
            <v-card outlined class="left-outline-panel">
                <v-card-text class="d-flex flex-row justify-space-between">
                    <v-btn text block class="d-flex flex-row justify-space-between btn-lower-case btn-loose" @click.stop="showAddPetDialog(true)">
                        <v-row class="d-flex flex-row flex-wrap align-center justify-space-between">
                            <v-col :class="$vuetify.breakpoint.smAndDown ? 'pa-0':''" cols="10">
                                <v-row class="d-flex flex-row align-center">
                                    <v-col cols="auto">
                                        <v-img
                                            alt="Pet Family Plan"
                                            :src="require('@/assets/svg/icons-add-another-pet.svg')"
                                            class="shrink"
                                            contain
                                            width="auto"
                                            :max-width="$vuetify.breakpoint.smAndUp ? '69' : '50'"
                                            :max-height="$vuetify.breakpoint.smAndUp ? '69' : '50'"
                                        />
                                    </v-col>
                                    <v-col cols="6" class="text-left">
                                        <span class="text-h6  font-weight-bold ml_darkblue--text">Add another pet</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="pa-0" cols="auto">
                                <v-sheet class="rounded-circle" color="ml_darkblue">
                                    <v-icon large class="white--text">mdi-plus</v-icon>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template> 
<script> 

    import PetComponent from '../shared/petComponent.vue';

    import Pricing from './Pricing.vue';
    import Customize from './Customize.vue';
    
    import DotLoader from '@/components/shared/DotLoader.vue';
    
    export default { 
        components: {
            PetComponent,
            Pricing,
            Customize,
            DotLoader
        },
        props: {
            announcePricingSummary: {
                type: Function,
                validator: function(value) {
                    return typeof value === 'function';
                }
            },
            isCustomPlanActive: {
                type: Boolean,
                default: false
            },
            loadingNewPrices: {
                type: Boolean,
                default: false
            },
            loadingCompleteController: {
                type: Function,
                validator: function(value) {
                    return typeof value === 'function';
                }
            },
            pricingKey: {
                type: Number,
                default: 0
            },
        },
        data() { 
            return {  
                forceUpdateKey: 0, 
                selectedPlan: '',
                panelExpanded: false,

            }; 
        },
        computed: {
            pets() {
                return this.$store.getters.pets;
            },
            panelsExpanded: {
                get(){
                    return this.$store.getters.getPanelsExpanded;
                },
                set(value){
                    this.$store.commit('setPanelsExpanded',value);
                }
            },
            selectedPolicies() {
                return this.$store.getters.selectedPolicies;
            },
            petIndexesWithoutPlans() {
                const petIndexesArray = Array.from(this.pets.keys());
                const petPoliciesIndexesArray = this.selectedPolicies.map(p => p.petArrayIndex);

                return petIndexesArray.filter(index => !petPoliciesIndexesArray.includes(index));
            },
            getMonthlyPremium() {
                return index => {
                    const selectPlanIndex = this.selectedPolicies.findIndex(p => p.petArrayIndex === index);
                    if (selectPlanIndex !== -1) {
                        return this.selectedPolicies[selectPlanIndex].planDetails?.monthlyPremium || 0.00;
                    }
                    return 0.00;
                }
            },
            currentPetIndex() {
                return this.$store.getters.currentPetIndex;
            },
            isFamilyPlan: {
                get(){
                    return this.$store.getters.isFamilyPlan;
                },
                set(value){
                    this.$store.commit('setIsFamilyPlan',value);
                }
            },
            maxPetView() {
                return this.$store.getters.maxPetView;
            },
            defaultDynamicContent() {
                return this.$store.getters.getDefaultDynamicContent.DynamicJson;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            },
            owner() {
                return this.$store.getters.owner;
            },
            partner: function() {
                return this.$store.getters.partner;
            },
            getPetsQuotes() {
                return this.$store.getters.getPetsQuotes;
            },
            switchBackIndividual() {
                return this.$store.getters.getSwitchBackIndividual;
            }
        },
        created() {
            if (this.pets.length === 1) this.panelsExpanded = 0;
        },
        methods: {
            renderSummaryBar() {
                this.$emit("renderSummaryBar");
            },
            renderPricingComponent() {
                this.$emit("renderPricingComponent");
            },
            setIsCustomPlanActive() {
                this.$emit("setIsCustomPlanActive");
            },
            planChange(index) {
                const customizeComponent = this.isFamilyPlan 
                    ? this.$refs.customizeFamily 
                    : this.$refs.customize.find(component => component.currentPetIndex === index);

                if (customizeComponent) {
                    customizeComponent.planChange();
                    this.forceUpdateKey++;
                }
            },
            deselectTab(index) {
                const pricingCard = this.isFamilyPlan 
                    ? this.$refs.pricingCardsFamily 
                    : this.$refs.pricingCards.find(component => component.currentPetIndex === index);

                if (pricingCard) {
                    pricingCard.activeTab = null;
                    this.forceUpdateKey++;
                }
            },
            selectHighDeductibleTab(index) {
                const pricingCard = this.isFamilyPlan 
                    ? this.$refs.pricingCardsFamily 
                    : this.$refs.pricingCards.find(component => component.currentPetIndex === index);
                if (pricingCard) pricingCard.handleChange(2);
            },
            handlePanelClick(index) {
                if(!this.isFamilyPlan) {
                    const checkInterval = setInterval(() => {
                        const pricingCard = this.$refs.pricingCards.find(component => component.currentPetIndex === index);

                        if (pricingCard) {
                            clearInterval(checkInterval);
                            pricingCard.autoSelectPlan();
                            this.forceUpdateKey++;
                        }
                    }, 500);

                    setTimeout(() => { clearInterval(checkInterval); }, 30000);             
                }
            },
            async handlePetCreated(index) {
                if(!this.isFamilyPlan) this.updatePlansForPets(index);

                const checkInterval = setInterval(() => {
                    if(!this.isFamilyPlan && !this.switchBackIndividual) this.panelsExpanded = index;
                    
                    if(this.switchBackIndividual) {
                        this.panelsExpanded = 0;
                        index = 0;
                        this.$store.commit("setSwitchBackIndividual", false);
                    }
                     
                    const pricingCard = this.isFamilyPlan 
                        ? this.$refs.pricingCardsFamily 
                        : this.$refs.pricingCards?.find(component => component.currentPetIndex === index);

                    if (pricingCard) {
                        clearInterval(checkInterval);
                        pricingCard.autoSelectPlan();
                        this.forceUpdateKey++;
                    }
                }, 500);

                setTimeout(() => { clearInterval(checkInterval); }, 30000);
            },
            AddPetPetDialogFormFinished(status, petIndex) {
                this.$emit("AddPetPetDialogFormFinished", status, petIndex);
            },
            updatePlan() {
                if(this.isFamilyPlan) {
                    this.$store.commit("setViewingFamilyPlan", true);
                    this.$store.commit("setIsFamilyPlan", true);
                    this.$emit("handleShowFamilyPlanClick");
                } 
                
                if(!this.isFamilyPlan) {
                    this.$store.commit("setViewingFamilyPlan", false);
                    this.$store.commit("setIsFamilyPlan", false);
                    this.$emit("handleShowIndividualPlanClick");
                }
            },
            showAddPetDialog(status) {
                this.$emit("showAddPetDialog", status);
            },
            updatePlansAfterDiscountApply(quote, petsQuotes, customPlan){

                if(!this.isFamilyPlan) {
                    this.pets.forEach((pet, index) => {
                        const customizeComponent = this.$refs.customize.find(component => component.currentPetIndex === index);
                        if(customizeComponent) customizeComponent.selectPlanForPetWithCustomization(petsQuotes, index);
                    });
                    const checkInterval = setInterval(() => this.forceUpdateKey++, 2000);
                    setTimeout(() => clearInterval(checkInterval), 7000);
                } else {
                    const customizeComponent = this.$refs.customizeFamily;

                    if(customizeComponent && customPlan) customizeComponent.selectCustomPlanAfterDiscountApplied(quote, petsQuotes);
                    const checkInterval = setInterval(() => this.forceUpdateKey++, 2000);
                    setTimeout(() => clearInterval(checkInterval), 7000);
                }
            },
            updatePlansForPets(petCreated) {
                this.pets.forEach((pet, index) => {
                    if (index !== petCreated || this.pets.length === 1) {
                        if(!this.selectedPolicies?.[index]?.customPlan) {
                            if(this.selectedPolicies?.[index]?.planId) {
                                this.selectPlan(this.selectedPolicies[index].planId, index);
                            }
                        } else {
                            this.selectCustomPlan(index);
                        }

                    }
                });
            },
            selectPlan(planIndex, petIndex) {
                const plan = this.getPetsQuotes[petIndex].fastQuotes.find(x => x.planId == planIndex);

                if(plan) {
                    let selectedPlans = this.selectedPolicies;

                    const existingPlan = selectedPlans.findIndex(p => p.petArrayIndex === petIndex);

                    const selectedPlanData = {
                        "planId": plan.planId,
                        "limit": plan.limit,
                        "deductible": plan.deductible,
                        "reimbursement": plan.reimbursements,
                        "riderId": plan.rider.id,
                        "petArrayIndex": petIndex,
                        "customPlan": false,
                        "planDetails": plan
                    };

                    if (existingPlan !== -1) {
                        this.$store.commit("updateSelectedPolicies", { index: existingPlan, planData: selectedPlanData});
                    } else {
                        if(selectedPlans.length < petIndex + 1) {
                            const emptySpaceCount = petIndex - selectedPlans.length;
                            for (let i = 0; i < emptySpaceCount; i++) {
                                this.$store.commit("addEmptyPolicies");
                            }
                        }
                        this.$store.commit("updateSelectedPolicies", { index: petIndex, planData: selectedPlanData });
                    }
                }
            },
            getRiderIndex(quote, options, option) {
                return quote[options].findIndex(opt => opt.id === option);
            },
            selectCustomPlan(petIndex) {
                let pets = [];
                const quotes = this.getPetsQuotes[petIndex];
                const petSelectedPolicy = this.selectedPolicies[petIndex];

                pets.push(this.pets[petIndex]);

                const customizedPlan = { 
                    planOptions: {
                        limit: petSelectedPolicy.limit,
                        deductible: petSelectedPolicy.deductible,
                        reimbursements: petSelectedPolicy.reimbursement,
                        rider: quotes.riderOptions[this.getRiderIndex(quotes, "riderOptions", petSelectedPolicy.riderId)]
                    }, 
                    pets: pets 
                };

                this.$store.dispatch("getCustomizedFastQuote", { customizedPlan: customizedPlan, petIndex: petIndex }).then((response) => {

                    Object.entries(customizedPlan.planOptions).forEach((entry) => {
                        const [key, value] = entry;
                        response[key] = value;
                    });

                    this.selectPlanForPet(quotes, petSelectedPolicy.reimbursement, petSelectedPolicy.limit, petSelectedPolicy.deductible, petSelectedPolicy.riderId, petIndex, response);

                }).catch((err) => {
                //TODO: HANDLE API ERROR
                });
            },
            getOptionIndex(quote, options, option) {
                return quote[options].findIndex(opt => opt === option);
            },
            getPlanDetailsForPet(planId, reimbursement, limit, deductible, riderId, petIndex, customizedPlan) {
                return {
                    "planId": planId,
                    "limit": limit,
                    "deductible": deductible,
                    "reimbursement": reimbursement,
                    "riderId": riderId,
                    "petArrayIndex": petIndex,
                    "customPlan": true,
                    "planDetails": customizedPlan
                }
            },
            async selectPlanForPet(quote, reimbursement, limit, deductible, riderId, petIndex, customizedPlan) {
                let selectedPolicies = [];

                const limitIndex = this.getOptionIndex(quote, "limitOptions", limit);
                const planIdIndex = quote.limitsWithFilings.findIndex(l => l.limit === quote.limitOptions[limitIndex]);
                const planId = quote.limitsWithFilings[planIdIndex].planId;

                if (this.pets.length > 1 && !this.isFamilyPlan) {
                    selectedPolicies = this.selectedPolicies;
                    let existingPlan = selectedPolicies.findIndex(p => p.petArrayIndex === petIndex);

                    existingPlan !== -1
                        ? selectedPolicies[existingPlan] = this.getPlanDetailsForPet(planId, reimbursement, limit, deductible, riderId, petIndex, customizedPlan)
                        : selectedPolicies.push(this.getPlanDetailsForPet(planId, reimbursement, limit, deductible, riderId, petIndex, customizedPlan));
                } else {
                    selectedPolicies.push(this.getPlanDetailsForPet(planId, reimbursement, limit, deductible, riderId, petIndex, customizedPlan));
                }

                this.$store.commit("setSelectedPolicies", selectedPolicies);
            },
            calculateFamilyPlanTotal: function () {
                let result = 0;

                if (this.selectedPolicies.length > 0) {
                    result = this.selectedPolicies[0].planDetails.monthlyPremium;
                }

                return this.formatCurrencyValue(result);
            },
            formatCurrencyValue(value) {
                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(value);
            },
        }
    }; 
</script>