<template>
    <div class="btn-toggle-container d-flex flex-row" >
        <label id="RoutineCare" for="routineCare"> 
            {{ isMetGen ? "Optional Preventive Care: " : "Optional Routine Checkups: " }}
            <a aria-label="Go to Footnote 4 on Routine Care." id="anchorFootnote4" ref="anchorFootnote4" @click="goToElementAndFocus('footnote-4', 'footnote4')"><sup>[4]</sup></a> 
            <Popover :title="popOverTitle" content="Any treatment, service or procedure for the purpose of prevention of injury or illness or for promotion of general health, where there has been no injury or illness."></Popover> 
        </label>
        <v-btn-toggle
            aria-labelledby= "RoutineCare"
            v-model="rider"
            class="d-flex" 
            rounded
            mandatory
            id="routineCare"
            role="group"
        >
            <v-btn v-for="(rider, index) in riderOptions" 
                :key="rider.id"
                :id="'btn-rider-' + rider.id"
                :aria-labelledby="'RoutineCare btn-rider-' + rider.id"
                :retain-focus-on-click="true"
                width="50%"
                :value="rider.id"
                :aria-pressed="rider === rider.id ? 'true': 'false'"
                :ref="rider.displayName"
                @change="handleBtnChange(rider.id)"
            >
                <span :id="'btn-rider-' + rider.id">{{ rider.displayName }}</span>
            </v-btn>
        </v-btn-toggle>
    </div>
</template>
<script>
    import Popover from '../shared/Popover.vue';

    import routineCare from '../global/js/routineCare.js';
    
    export default {
        mixins: [routineCare],
        props: ['routinePlanConfig', 'riderOptions', 'announcePriceCardChange'],
        components: { Popover },
        data() {
            return {
                rider: null
            }
        },
        created() {
            this.popOverTitle = `What is ${this.routineCareLabel}?`;
        }, 
        watch: {
            currentPetIndex:  function () {
                if(!this.isFamilyPlan && this.selectedPolicies[this.currentPetIndex]) {

                    if(this.selectedPolicies[this.currentPetIndex].planDetails.rider.id === 0) {
                        this.rider = this.riderOptions[0].id;
                    } else {
                        this.rider = this.riderOptions[1].id;
                    }
                    
                }
            },
        },
        methods: {
            handleBtnChange(riderId) {
                const newRoutineCare = this.isMetGen ? riderId : this.riderOptions[this.routinePlanConfig.routineCareValue].id;
                this.updateRoutineCare(newRoutineCare);
            },
            updateRoutineCare(newRoutineCare) { 
                // TODO:enable it when available 
                // this.announcePriceCardChange();
                this.$store.commit("setLoadingNewPrices", true);
                this.$store.dispatch("getRoutineCareFastQuotes", newRoutineCare).then(response => {
                    this.$store.commit("setLoadingNewPrices", false);
                    this.$emit("renderSummaryBar");
                    this.$emit("updateRoutineCare", response);
                    this.$store.commit("setDotLoader", false);           
                }).catch((err) => {
                    //TODO: HANDLE ERROR
                });
            },
            resetPreventiveCare() {
                setTimeout(() => {
                    this.rider = this.riderOptions[0].id;
                }, 500);
            },
            goBackFromCustomize() {
                if(this.selectedPolicies[this.currentPetIndex].customPlan === false) {
                    if(!this.isFamilyPlan) {
                        if(this.selectedPolicies[this.currentPetIndex].planDetails.rider.id === 0) {
                            this.rider = this.riderOptions[0].id;
                        } else {
                            this.rider = this.riderOptions[1].id;
                        }
                    } else {
                        if(this.$store.state.familyWithWellness === 0) {
                            this.rider = this.riderOptions[0].id;
                        } else {
                            this.rider = this.riderOptions[1].id;
                        }
                    }
                }
            }
        },
    }
</script>
