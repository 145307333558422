<template>
    <v-tabs
      background-color="transparent"
      centered
      v-model="activeTab"
      :optional="isOptional()"
      @change="handleChange"
      class="plan-tabs"
    >
     <v-tab 
        v-for="(plan, index) in plans"
        :key="plan.planId"
        :value="index"
        aria-controls="customization side-col"
    >
        {{ getPricingTitle(index) }}
     </v-tab>
    </v-tabs>
</template>
<script>
    export default {
        props: {
            currentPet: {
                type: Object
            },
            currentPetIndex: {
                type: Number
            },
        },
        computed: {
            pets() {
                return this.$store.getters.pets;
            },
            plans: function () {
                return this.isFamilyPlan 
                    ? this.$store.getters.currentFamilyPlans
                    : this.$store.getters.getPetsQuotes?.[this.currentPetIndex]?.fastQuotes;
            },
            viewingFamilyPlan: function () {
                return this.$store.getters.viewingFamilyPlan;
            },
            selectedPolicies() {
                return this.$store.getters.selectedPolicies;
            },
            isFamilyPlan() {
                return this.$store.getters.isFamilyPlan;
            },
            petsQuotes() {
                return this.$store.getters.getPetsQuotes;
            },
        },
        data() {
            return {
                activeTab: 0,
            }
        },
        watch: {
            currentPetIndex() { 
                this.setInitialActiveTab();
            },
            plans: function() {
                this.autoSelectPlan();
                this.selectPlanAferUpdate();
            },
        },
        mounted() {
            this.setInitialActiveTab();
            this.assignTabRoles();
        },
        methods: {
            setInitialActiveTab() {
                const selectedPlan = this.selectedPolicies.find(p => p.petArrayIndex === this.currentPetIndex);

                if (selectedPlan) {
                    const selectedPlanIndex = this.plans.findIndex(p => p.planId === selectedPlan.planId);
                    if (selectedPlanIndex >= 0) {
                        this.selectedPolicies?.[this.currentPetIndex]?.customPlan
                            ? this.activeTab = null
                            : this.activeTab = selectedPlanIndex;
                        this.$emit("planChange");
                    }
                }
            },
            getPricingTitle(index) {
                switch(index) {
                    case 0:
                        return "Balanced";
                    case 1:
                        return "Recommended";
                    case 2:
                        return "High Deductible"
                    default:
                        return "";
                }
            },
            isOptional() {
                if(this.selectedPolicies[this.currentPetIndex]?.customPlan) {
                    return true;
                }
                return false;
            },
            handleChange(index) {
                if(this.plans[index]) {
                    this.activeTab = index;
                    this.selectPlan(this.plans[index].planId);
                    this.$emit("planChange");
                } 
            },
            autoSelectPlan() { 
                if(this.selectedPolicies.findIndex(policy => policy.petArrayIndex === this.currentPetIndex) === -1){
                    this.plans.forEach((plan, index) => {
                        if(this.plans.length === 3) {
                            if(index === 1) this.selectPlanHelper(plan.planId);
                        }

                        if(this.plans.length === 1 || this.plans.length === 2) {
                            if(index === 0) this.selectPlanHelper(plan.planId);
                        }
                    });
                }

                if(this.$store.state.isFamilyPlan) {
                    this.selectPlanHelper(this.$store.state.currentPlans[1].planId);
                }
            },
            selectPlanHelper(planId) {
                if(!this.selectedPolicies?.[this.currentPetIndex]?.customPlan) {
                    this.selectPlan(planId);
                    this.setInitialActiveTab();
                }
            },
            assignTabRoles()
            {
                let radioContainer = document.querySelector("div.v-slide-group__wrapper > div");
                radioContainer.setAttribute("role", "tablist");
                radioContainer.parentNode.parentNode.removeAttribute("role");
            },
            selectPlan(planIndex) {
                const plan = this.$store.state.isFamilyPlan 
                    ? this.plans.find(x => x.planId == planIndex)
                    : this.petsQuotes[this.currentPetIndex].fastQuotes.find(x => x.planId == planIndex);

                if(plan) {
                    let selectedPlans = this.selectedPolicies;

                    if (this.viewingFamilyPlan) {
                        selectedPlans = [];
                    }

                    const existingPlan = selectedPlans.findIndex(p => p.petArrayIndex === this.currentPetIndex);

                    const selectedPlanData = {
                        "planId": plan.planId,
                        "limit": plan.limit,
                        "deductible": plan.deductible,
                        "reimbursement": plan.reimbursements,
                        "riderId": plan.rider.id,
                        "petArrayIndex": this.currentPetIndex,
                        "customPlan": false,
                        "planDetails": plan
                    };

                    if (existingPlan !== -1) {
                        this.$store.commit("updateSelectedPolicies", { index: existingPlan, planData: selectedPlanData});
                    } else {
                        if(selectedPlans.length < this.currentPetIndex + 1) {
                            const emptySpaceCount = this.currentPetIndex - selectedPlans.length;
                            for (let i = 0; i < emptySpaceCount; i++) {
                                this.$store.commit("addEmptyPolicies");
                            }
                        }
                        this.$store.commit("updateSelectedPolicies", { index: this.currentPetIndex, planData: selectedPlanData });
                    }
                    
                    let isFamilyPlan = false;
                    if (this.viewingFamilyPlan) {
                        isFamilyPlan = true;
                    }
                    this.$store.commit("setIsFamilyPlan", isFamilyPlan);
                    this.$emit("renderSummaryBar");
                    this.$emit("renderPrincingComponent");
                    this.$store.commit("setShowRoutineCare", true);
                    this.$emit("setIsCustomPlanActive", false);
                }
            },
            selectPlanAferUpdate() {
                if(!this.$store.state.isFamilyPlan && !this.selectedPolicies[this.currentPetIndex]?.customPlan) {
                    this.selectPlan(this.selectedPolicies[this.currentPetIndex].planId);
                }
            }
        }
    }
</script>