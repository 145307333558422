<template>
    <v-tabs
      background-color="transparent"
      centered
      v-model="activeTab"
      :optional="isOptional()"
      @change="handleChange"
      class="plan-tabs"
    >
     <v-tab 
        v-for="(plan, index) in plans"
        :key="plan.planId"
        :value="index"
    >
        {{ getPricingTitle(index) }}
     </v-tab>
    </v-tabs>
</template>
<script>
    import pricing from '../global/js/pricing.js';
    export default {
        mixins: [pricing],
        data() {
            return {
                activeTab: 0,
            }
        },
        watch: {
            currentPetIndex() { 
                this.setInitialActiveTab();
            },
            plans: function() {
                this.autoSelectPlan();
            },
        },
        mounted() {
            this.setInitialActiveTab();
        },
        methods: {
            setInitialActiveTab() {
                const selectedPlan = this.selectedPolicies.find(p => p.petArrayIndex === this.currentPetIndex);

                if (selectedPlan) {
                    const selectedPlanIndex = this.plans.findIndex(p => p.planId === selectedPlan.planId);
                    if (selectedPlanIndex >= 0) {
                        this.selectedPolicies?.[this.currentPetIndex]?.customPlan
                            ? this.activeTab = null
                            : this.activeTab = selectedPlanIndex;
                        this.$emit("planChange");
                    }
                }
            },
            getPricingTitle(index) {
                switch(index) {
                    case 0:
                        return "Balanced";
                    case 1:
                        return "Recommended";
                    case 2:
                        return "High Deductible"
                    default:
                        return "";
                }
            },
            isOptional() {
                if(this.selectedPolicies[this.currentPetIndex]?.customPlan) {
                    return true;
                }
                return false;
            },
            handleChange(index) {
                if(this.plans[index]) {
                    this.activeTab = index;
                    this.selectPlan(this.plans[index].planId);
                    this.$emit("planChange");
                } 
            },
            autoSelectPlan() { 
                if(this.selectedPolicies.findIndex(policy => policy.petArrayIndex === this.currentPetIndex) === -1){
                    this.plans.forEach((plan, index) => {
                        if(this.plans.length === 3) {
                            if(index === 1) this.selectPlanHelper(plan.planId);
                        }

                        if(this.plans.length === 1 || this.plans.length === 2) {
                            if(index === 0) this.selectPlanHelper(plan.planId);
                        }
                    });
                } 

                if(this.$store.state.isFamilyPlan) {
                    this.selectPlanHelper(this.$store.state.currentPlans[1].planId);
                }
            },
            selectPlanHelper(planId) {
                if(!this.selectedPolicies?.[this.currentPetIndex]?.customPlan) {
                    this.selectPlan(planId);
                    this.setInitialActiveTab();
                }
            },
        }
    }
</script>