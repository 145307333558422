<template>
    <div role="tabpanel" aria-label="Customization" id="customization">
        <v-container>
            <div class="btn-toggle-container d-flex flex-column justify-center my-7">
                
                <label class="text-center mb-1 d-flex justify-center align-center" id="Reimbursement" for="reimbursement"> 
                    Reimbursement 
                    <a  aria-label="Go to Footnote 4 on Reimbursement." id="anchorFootnote4"  ref="anchorFootnote4" @click="goToElementAndFocus('footnote-4', 'footnote4')"><sup>[4]</sup></a>
                    <span>
                        <v-menu
                            v-model="popoverReimbursement"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            max-width="300px"
                            offset-x
                            offset-y
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="ml_darkgray"
                                fab
                                dark
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                elevation="0"
                                class="mb-1 ml-1 btn-tooltip"
                                >
                                    <v-icon x-small class="white--text">mdi-help</v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="text-h6" >
                                    <v-row align="center" no-gutters>
                                        <v-col cols="11" class="text-h6">
                                            <v-icon>mdi-help-circle</v-icon> What is Reimbursement?
                                        </v-col>
                                        <v-col cols="1" class="text-right">
                                            <v-icon @click="closeMenu('popoverReimbursement')">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                    A reimbursement is the money you receive for covered expenses after submitting a claim. 
                                    Reimbursement percentage is the portion of covered expenses you can be reimbursed for after meeting your deductible.  
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </span>
                </label>
                <v-btn-toggle
                    aria-labelledby= "Reimbursement"
                    v-model="reimbursement"
                    class="d-flex" 
                    rounded
                    mandatory
                    id="reimbursement"
                    role="group"
                >
                    <v-btn small v-for="(option, index) in this.reimbursementOptions"
                        :key="index"
                        :id="'btn-reimbursement-' + option"
                        :aria-labelledby="'Reimbursement btn-reimbursement-' + option"
                        :retain-focus-on-click="true"
                        :value="option"
                        :width="100 / reimbursementOptions.length + '%'"
                        :aria-pressed="reimbursement === option ? 'true': 'false'"
                        :ref="'reimbursement-' + option"
                        @change="handleReimbursementChange(option)"
                        :disabled="disableReimbursement(option)"
                    >
                        <span :id="'btn-reimbursement-' + option">
                            {{ Math.floor(option * 100) + "%" }}
                        </span>
                    </v-btn>
                </v-btn-toggle>
            </div>

            <div class="btn-toggle-container d-flex flex-column justify-center mb-7">
                <label class="text-center mb-1 d-flex justify-center align-center" id="AnnualBenefit" for="annualBenefit"> 
                    Annual Benefit
                    <span>
                        <v-menu
                            v-model="popoverAnualBenefit"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            max-width="300px"
                            offset-y
                            offset-x
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="ml_darkgray"
                                fab
                                dark
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                elevation="0"
                                class="mb-1 ml-1 btn-tooltip"
                                >
                                    <v-icon x-small class="white--text">mdi-help</v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="text-h6" >
                                    <v-row align="center" no-gutters>
                                        <v-col cols="11" class="text-h6">
                                            <v-icon>mdi-help-circle</v-icon> What is Annual Benefit?
                                        </v-col>
                                        <v-col cols="1" class="text-right">
                                            <v-icon @click="closeMenu('popoverAnualBenefit')">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text> 
                                    The annual limit is the maximum amount of money you can be reimbursed through your insurance policy in a given policy period.
                                    This limit doesn't include your deductible, and it will reset when a new policy period begins.
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </span>
                </label>
                <v-btn-toggle
                    aria-labelledby= "AnnualBenefit"
                    v-model="limit"
                    class="d-flex" 
                    rounded
                    mandatory
                    id="annualBenefit"
                    role="group"
                >
                    <v-btn v-for="(option, index) in this.limitOptions"
                        :key="index"
                        :id="'btn-reimbursement-' + option"
                        :aria-labelledby="'AnnualBenefit btn-annualBenefit-' + option"
                        :retain-focus-on-click="true"
                        :value="option"
                        :width="100 / limitOptions.length + '%'"
                        :aria-pressed="limit === option ? 'true': 'false'"
                        :ref="'annualBenefit-' + option"
                        @change="handleLimitChange(option)"
                    >
                        <span :id="'btn-annualBenefit-' + option">
                            {{ `$${option.toLocaleString()}` }}
                        </span>
                    </v-btn>
                </v-btn-toggle>
            </div>

            <div class="btn-toggle-container d-flex flex-column justify-center mb-7">
                <label class="text-center mb-1 d-flex justify-center align-center" id="Deductible" for="deductible"> 
                    Deductible
                    <span>
                        <v-menu
                            v-model="popoverDeductible"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            max-width="300px"
                            offset-y
                            offset-x
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="ml_darkgray"
                                fab
                                dark
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                elevation="0"
                                class="mb-1 ml-1 btn-tooltip"
                                >
                                    <v-icon x-small class="white--text">mdi-help</v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="text-h6" >
                                    <v-row align="center" no-gutters>
                                        <v-col cols="11" class="text-h6">
                                            <v-icon>mdi-help-circle</v-icon> What is Deductible?
                                        </v-col>
                                        <v-col cols="1" class="text-right">
                                            <v-icon @click="closeMenu('popoverDeductible')">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                    A deductible is the amount of money you pay for your pet's health care before your insurance provider starts reimbursing for covered expenses.    
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </span>
                </label>
                <v-btn-toggle
                    aria-labelledby= "Deductible"
                    v-model="deductible"
                    class="d-flex" 
                    rounded
                    mandatory
                    id="deductible"
                    role="group"
                >
                    <v-btn v-for="(option, index) in this.deductibleOpts"
                        :key="index"
                        :id="'btn-deductible-' + option"
                        :aria-labelledby="'Deductible btn-deductible-' + option"
                        :retain-focus-on-click="true"
                        :value="option"
                        :width="100 / deductibleOpts.length + '%'"
                        :aria-pressed="deductible === option ? 'true': 'false'"
                        :ref="'deductible-' + option"
                        @change="handleDeductibleChange(option)"
                        :disabled="disableDeductible(option)"
                    >
                        <span :id="'btn-deductible-' + option">
                            {{ `$${option.toLocaleString()}` }}
                        </span>
                    </v-btn>
                </v-btn-toggle>
            </div>

            <div class="btn-toggle-container d-flex flex-row justify-space-between justify-center mb-7" v-if="showWellnessSteteRestricted(partner.id, owner.state)">
                <label class="text-left d-flex flex-column mb-1" id="RoutineCare" for="routineCare"> 
                    <span class="body-2 font-weight-bold">
                        Optional Preventive Care:
                        <span>
                            <v-menu
                                v-model="popoverPreventiveCare"
                                :close-on-content-click="false"
                                :nudge-width="200"
                                max-width="300px"
                                offset-x
                                offset-y
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="ml_darkgray"
                                    fab
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    elevation="0"
                                    class="mb-1 ml-1 btn-tooltip"
                                    >
                                        <v-icon x-small class="white--text">mdi-help</v-icon>
                                    </v-btn>
                                </template>

                                <v-card>
                                    <v-card-title class="text-h6" >
                                        <v-row align="center" no-gutters>
                                            <v-col cols="11" class="text-h6">
                                                <v-icon>mdi-help-circle</v-icon> What is Preventive Care?
                                            </v-col>
                                            <v-col cols="1" class="text-right">
                                                <v-icon @click="closeMenu('popoverPreventiveCare')">mdi-close</v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        Preventive care coverage is insurance that can cover expenses related to preventing injury or illness  or keeping a pet healthy when there's been no injury or illiness --
                                        such as vaccinations, teeth cleaning, or parasite prevention.
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </span>

                    </span>
                    <span>Sign me up for Preventive Care Coverage</span>
                </label>
                <div>
                    <v-btn-toggle
                        aria-labelledby= "RoutineCare"
                        v-model="riderId"
                        class="d-flex" 
                        rounded
                        mandatory
                        id="routineCare"
                        role="group"
                    >
                        <v-btn v-for="(rider, index) in this.riderOptions"
                            :key="rider.id"
                            :id="'btn-routineCare-' + rider.id"
                            :aria-labelledby="'RoutineCare btn-routineCare-' + rider.id"
                            :retain-focus-on-click="true"
                            :value="rider.id"
                            :width="100 / riderOptions.length + '%'"
                            :aria-pressed="riderId === rider.id ? 'true': 'false'"
                            :ref="rider.displayName"
                            @change="handleRoutineCareChange(rider.id)"
                        >
                            <span :id="'btn-routineCare-' + rider.id">
                                <!-- {{ rider.displayName }} -->
                                <span v-if="rider.displayName === 'None'">No</span>
                                <span v-else>Yes</span>
                            </span>
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </div>

            <DotLoader :loading="loadingCustomPrice" :loaderText="this.customPlanLoadText" :onLoadingComplete="onLoderComplete" />
        </v-container>
    </div>
</template>
<script>
    import DotLoader from '../../shared/DotLoader.vue';
    export default {
        props: {
            currentPetIndex: {
                type: Number
            },
        },
        components: { DotLoader },
        data() {
            return {
                reimbursement: null,
                popoverReimbursement: false,
                popoverDeductible: false,
                popoverAnualBenefit: false,
                popoverPreventiveCare: false,
                limit: null,
                deductible: null,
                riderId: null,
                deductibleOpts: [],
                hihgDeductible: 2500,
                customPlanLoadText: "Loading Updated Price",
                loadingCustomPrice: false,
                onLoderComplete: () => {}
            }
        },
        created() {
        
        },
        mounted() {
            this.getDeductibleOptions();
        },
        computed: {
            isFamilyPlan() {
                return this.$store.getters.isFamilyPlan;
            },
            quote() {
                return this.$store.getters.getQuote;
            },
            petsQuotes() {
                return this.$store.getters.getPetsQuotes;
            },
            reimbursementOptions() {
                return this.getOptions("reimbursementOptions");
            },
            limitOptions() {
                return this.getOptions("limitOptions");
            },
            deductibleOptions() {
                return this.getOptions("deductibleOptions");
            },
            riderOptions() {
                return this.getOptions("riderOptions");
            },
            selectedPolicies() {
                return this.$store.getters.selectedPolicies;
            },
            pets() {
                return this.$store.getters.pets;
            },
            owner() {
                return this.$store.getters.owner;
            },
            partnerId: function () {
                return this.$store.getters.partnerId;
            },
            partner: function () {
                return this.$store.getters.partner;
            },
            currentPet() {
                return this.$store.getters.currentPet;
            },
            leadId(){
                return this.$store.getters.leadId;
            },
        },
        watch: {
            currentPetIndex() { 
                this.setValues(this.selectedPolicies[this.currentPetIndex]);
            },
        },
        methods: {
            handleReimbursementChange(reimbursement) {
                if (!this.deductibleOpts.includes(this.hihgDeductible) || this.deductibleOpts.includes(this.hihgDeductible) && reimbursement !== 0.8) {
                    this.getCustomPlan(reimbursement, this.limit, this.getDeductibleValue(), this.riderId);
                }
            },
            handleLimitChange(limit) {
                if (!this.deductibleOpts.includes(this.hihgDeductible) || this.deductibleOpts.includes(this.hihgDeductible) && limit !== 10000) {
                    this.getCustomPlan(this.reimbursement, limit, this.getDeductibleValue(), this.riderId);
                }
            },
            handleDeductibleChange(deductible) {
                if (deductible !== this.hihgDeductible) {
                    this.getCustomPlan(this.reimbursement, this.limit, deductible, this.riderId);
                } else {
                    this.$emit("selectHighDeductibleTab");
                }
            },
            handleRoutineCareChange(riderId) {
                this.getCustomPlan(this.getReimbursementValue(riderId), this.limit, this.deductible, riderId);
            },
            getOptions(optionsName) {
                if(!this.isFamilyPlan && !this.petsQuotes) return [];

                if (this.isFamilyPlan) return this.quote[optionsName];

                // const checkInterval = setInterval(() => {
                //     if (this.petsQuotes[this.currentPetIndex] && this.petsQuotes[this.currentPetIndex][optionsName]) {
                //         clearInterval(checkInterval);
                //         console.log("Data is now available: ", this.petsQuotes[this.currentPetIndex][optionsName]);
                //         // You can use the data here
                //     }
                // }, 1000);
                return this.petsQuotes?.[this.currentPetIndex]?.[optionsName];
            },
            getOptionIndex(quote, options, option) {
                return quote[options].findIndex(opt => opt === option);
            },
            getDeductibleOptions() {
                const hihgDeductible = 2500;
                const currentPolicy = this.selectedPolicies[this.currentPetIndex]; 
                let deductibleOptions = this.getOptions("deductibleOptions");

                if (currentPolicy && !currentPolicy.customplan) {
                    if (currentPolicy.deductible === 2500) {
                        if (!deductibleOptions.includes(hihgDeductible)) deductibleOptions.push(hihgDeductible);
                    } else {
                        const index = deductibleOptions.indexOf(hihgDeductible);
                        if (index !== -1) deductibleOptions.splice(index, 1);
                    }
                } 

                this.deductibleOpts = deductibleOptions;
            },
            getRiderIndex(quote, options, option) {
                return quote[options].findIndex(opt => opt.id === option);
            },
            getDeductibleValue() {
                if (this.deductibleOpts.includes(this.hihgDeductible) && this.deductible === this.hihgDeductible) {
                    const index = this.deductibleOpts.indexOf(this.hihgDeductible);
                    this.deductible = this.deductibleOpts[index - 1];
                    
                    return this.deductibleOpts[index - 1];
                }
                return this.deductible;
            },
            getReimbursementValue(riderId) {
                if((riderId === 164 && this.partner.id !== null && (this.stateRestricted(this.owner.state)) && this.isPetUnderLimit(this.currentPet, false))) {
                    
                    const index = this.reimbursementOptions.indexOf(0.9);
                    this.reimbursement = this.reimbursementOptions[index - 1];
                    
                    return this.reimbursementOptions[index - 1];
                }
                return this.reimbursement;
            },
            getCustomPlan(reimbursement, limit, deductible, riderId) {
                let pets = [];
                // if family plans are not being shown use pet own values base on the current -
                // pet (LimitOptions, DeductibleOptions, ReimbursementOptions, RiderOptions...)
                const quote = this.isFamilyPlan ? this.quote : this.petsQuotes[this.currentPetIndex];
                const planOptions = {
                    limit: limit,
                    deductible: deductible,
                    reimbursements: reimbursement,
                    rider: quote.riderOptions[this.getRiderIndex(quote, "riderOptions", riderId)]
                };

                this.loadingCustomPrice = true;

                // CHECK IF FAMILY PLAN
                this.isFamilyPlan ? pets = this.pets : pets.push(this.pets[this.currentPetIndex]);

                const customizedPlan = { planOptions: planOptions, pets: pets };

                this.$store.dispatch("getCustomizedFastQuote", { customizedPlan: customizedPlan, petIndex: this.currentPetIndex }).then((response) => {
                    this.currentCustomizedPlan = response;

                    Object.entries(planOptions).forEach((entry) => {
                        const [key, value] = entry;
                        this.currentCustomizedPlan[key] = value;
                    });

                    this.selectCustomPlan(quote, reimbursement, limit, deductible, riderId);

                    this.loadingCustomPrice = false;
                    this.$store.commit("setDotLoader", false);

                }).catch((err) => {
                    //TODO: HANDLE API ERROR
                });
            },
            async selectCustomPlan(quote, reimbursement, limit, deductible, riderId) {
                let selectedPolicies = [];

                const limitIndex = this.getOptionIndex(quote, "limitOptions", limit);
                const planIdIndex = quote.limitsWithFilings.findIndex(l => l.limit === quote.limitOptions[limitIndex]);
                const planId = quote.limitsWithFilings[planIdIndex].planId;

                if (this.pets.length > 1 && !this.isFamilyPlan) {
                    selectedPolicies = this.selectedPolicies;
                    let existingPlan = selectedPolicies.findIndex(p => p.petArrayIndex === this.currentPetIndex);

                    existingPlan !== -1
                        ? selectedPolicies[existingPlan] = this.getPlanDetails(planId, reimbursement, limit, deductible, riderId)
                        : selectedPolicies.push(this.getPlanDetails(planId, reimbursement, limit, deductible, riderId));
                } else {
                    selectedPolicies.push(this.getPlanDetails(planId, reimbursement, limit, deductible, riderId));
                }

                this.$store.commit("setSelectedPolicies", selectedPolicies);

                this.$emit("renderSummaryBar");
                this.$emit("deselectTab");
            },
            getPlanDetails(planId, reimbursement, limit, deductible, riderId) {
                return {
                    "planId": planId,
                    "limit": limit,
                    "deductible": deductible,
                    "reimbursement": reimbursement,
                    "riderId": riderId,
                    "petArrayIndex": this.currentPetIndex,
                    "customPlan": true,
                    "planDetails": this.currentCustomizedPlan
                }
            },
            planChange() {
                this.getDeductibleOptions();
                this.setValues(this.selectedPolicies[this.currentPetIndex])
            },
            setValues(selectedPolicy) {
                this.reimbursement = selectedPolicy ? selectedPolicy.reimbursement : null;
                this.limit = selectedPolicy ?  selectedPolicy.limit : null;
                this.deductible = selectedPolicy ? selectedPolicy.deductible : null;
                this.riderId = selectedPolicy ? selectedPolicy.riderId : null;
            },
            selectCustomPlanAfterDiscountApplied(quote, petsQuotes) {
                let pets = [];
                // if family plans are not being shown use pet own values base on the current -
                // pet (LimitOptions, DeductibleOptions, ReimbursementOptions, RiderOptions...)
                const quotes = this.isFamilyPlan ? quote : petsQuotes[this.currentPetIndex];
                const reimbursement = this.reimbursement;
                const limit = this.limit;
                const deductible = this.deductible;
                const riderId = this.riderId;

                const planOptions = {
                    limit: limit,
                    deductible: deductible,
                    reimbursements: reimbursement,
                    rider: quotes.riderOptions[this.getRiderIndex(quotes, "riderOptions", riderId)]
                };

                this.loadingCustomPrice = true;

                // CHECK IF FAMILY PLAN
                this.isFamilyPlan ? pets = this.pets : pets.push(this.pets[this.currentPetIndex]);

                const customizedPlan = { planOptions: planOptions, pets: pets };

                this.$store.dispatch("getCustomizedFastQuote", { customizedPlan: customizedPlan, petIndex: this.currentPetIndex }).then((response) => {
                    this.currentCustomizedPlan = response;

                    Object.entries(planOptions).forEach((entry) => {
                        const [key, value] = entry;
                        this.currentCustomizedPlan[key] = value;
                    });

                    this.selectCustomPlan(quotes, reimbursement, limit, deductible, riderId);

                    this.loadingCustomPrice = false;
                    this.$store.commit("setDotLoader", false);

                }).catch((err) => {
                    //TODO: HANDLE API ERROR
                });
            },
            disableDeductible(currentDeductible) {
                if (this.selectedPolicies[this.currentPetIndex] && currentDeductible === 2500) {
                    if (this.selectedPolicies[this.currentPetIndex].customPlan) {
                        return this.reimbursement !== 0.8 || this.limit !== 10000 ? true : false;
                    } else {
                        return false;
                    }
                }
                return false;
            },
            disableReimbursement(currentReimbursement) {
                if (this.selectedPolicies[this.currentPetIndex] && currentReimbursement === 0.9) {
                    if (this.selectedPolicies[this.currentPetIndex].customPlan) {
                        return (this.riderId === 164 && this.partner.id !== null && (this.stateRestricted(this.owner.state)) && this.isPetUnderLimit(this.currentPet, false)) ? true : false;
                    } else {
                        return false;
                    }
                }
                return false;
            },
            /**
             * Later we can refactor this function and move it to 
             * pricingPage.js
             * This function is in charge of select the customize plans 
             * or the normal plans after refresh, navigation or discount apply.
            */
            selectPlanForPetWithCustomization(petsQuotes, petIndex) {
                if(this.selectedPolicies?.[petIndex]?.customPlan) {
                    let pets = [];

                    const quotes = petsQuotes[petIndex];
                    const petSelectedPolicy = this.selectedPolicies[petIndex];

                    this.loadingCustomPrice = true;

                    pets.push(this.pets[petIndex])

                    const customizedPlan = { 
                        planOptions: {
                            limit: petSelectedPolicy.limit,
                            deductible: petSelectedPolicy.deductible,
                            reimbursements: petSelectedPolicy.reimbursement,
                            rider: quotes.riderOptions[this.getRiderIndex(quotes, "riderOptions", petSelectedPolicy.riderId)]
                        }, 
                        pets: pets 
                    };

                    this.$store.dispatch("getCustomizedFastQuote", { customizedPlan: customizedPlan, petIndex: petIndex }).then((response) => {

                        Object.entries(customizedPlan.planOptions).forEach((entry) => {
                            const [key, value] = entry;
                            response[key] = value;
                        });

                        this.selectPlanForPet(quotes, petSelectedPolicy.reimbursement, petSelectedPolicy.limit, petSelectedPolicy.deductible, petSelectedPolicy.riderId, petIndex, response);

                        this.loadingCustomPrice = false;
                        this.$store.commit("setDotLoader", false);
                    }).catch((err) => {
                        //TODO: HANDLE API ERROR
                    });
                } else {
                    petsQuotes[petIndex].fastQuotes.map(quote => {
                        if (quote.planId === this.selectedPolicies?.[petIndex]?.planId) {
                            this.selectedPolicies[petIndex].planDetails = quote;
                        }
                    });
                }
            },
            async selectPlanForPet(quote, reimbursement, limit, deductible, riderId, petIndex, customizedPlan) {
                let selectedPolicies = [];

                const limitIndex = this.getOptionIndex(quote, "limitOptions", limit);
                const planIdIndex = quote.limitsWithFilings.findIndex(l => l.limit === quote.limitOptions[limitIndex]);
                const planId = quote.limitsWithFilings[planIdIndex].planId;

                if (this.pets.length > 1 && !this.isFamilyPlan) {
                    selectedPolicies = this.selectedPolicies;
                    let existingPlan = selectedPolicies.findIndex(p => p.petArrayIndex === petIndex);

                    existingPlan !== -1
                        ? selectedPolicies[existingPlan] = this.getPlanDetailsForPet(planId, reimbursement, limit, deductible, riderId, petIndex, customizedPlan)
                        : selectedPolicies.push(this.getPlanDetailsForPet(planId, reimbursement, limit, deductible, riderId, petIndex, customizedPlan));
                } else {
                    selectedPolicies.push(this.getPlanDetailsForPet(planId, reimbursement, limit, deductible, riderId, petIndex, customizedPlan));
                }

                this.$store.commit("setSelectedPolicies", selectedPolicies);
                this.$emit("renderSummaryBar");
            },
            getPlanDetailsForPet(planId, reimbursement, limit, deductible, riderId, petIndex, customizedPlan) {
                return {
                    "planId": planId,
                    "limit": limit,
                    "deductible": deductible,
                    "reimbursement": reimbursement,
                    "riderId": riderId,
                    "petArrayIndex": petIndex,
                    "customPlan": true,
                    "planDetails": customizedPlan
                }
            },
            closeMenu(menu) {
                this[menu] = false;
            }
        }
    };
</script>