<template>
    <v-row justify="center">
       <v-dialog v-model="isDialogShow" id="planTypeDetailDialog"  persistent max-width="400px"  @keydown="handleCloseOnEscape">
           <v-card v-if="isDialogShow" id="planTypeCardContainer"  :ripple="false">
               <v-card-title class="d-flex align-center justify-space-between">
                   <span role="heading" aria-level="2" class="headline" id="planTypeDetailTitle">
                       Change Plan Type
                   </span>
                   <v-btn icon
                           @click="closeDialog"
                           ref="planTypeCloseButton"
                           @keydown="handleShiftTabOnModalFirstElement"
                           aria-label="Exit plan type">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
               </v-card-title>
               <v-card-text>
                   <v-container>
                       <v-row>
                           <span>
                               A Family Plan groups all your pets under one plan with the same benefits.
                               <br>
                               Individual Plans allow you to have individual plans for each pet.
                           </span>
                           <div class="element-container">
                                <v-radio-group ref="radioPlanType" label="Update Plan Type" v-model="selectedPlan" column>
                                    <v-radio label="Family Plan" value="family"></v-radio>
                                    <v-radio label="Individual Plans" value="individual"></v-radio>
                                </v-radio-group>
                            </div>
                            <v-col cols="12" class="text-center">
                                <v-btn block color="ml_green" @keydown="handleTabOnModalLastElement" @click="updatePlan">Update Plan</v-btn>
                                <v-divider class="mt-10"></v-divider>
                                <span class="caption">* All fields are required.</span>
                            </v-col>
                       </v-row>
                   </v-container>
               </v-card-text>
               <v-card-actions>

               </v-card-actions>
           </v-card>
       </v-dialog>
   </v-row>
</template>
<script>
   export default {
       props: {
           dialog: false
       },
       watch: {
           dialog: function(dialog, oldDialog)  {
               this.isDialogShow = dialog;
               if(dialog && dialog != oldDialog) {
                   this.labelDialog();
                   this.focusCloseButton();
                   this.toggleHideBackgroundContianer();
               }
           }
       },
       data() {
           return {
               isDialogShow: false,
               selectedPlan: ''
           }
       },
       computed: {
           isFamilyPlan: function () {
               return this.$store.getters.isFamilyPlan;
           },
       },
       created() {
           this.selectedPlan = this.isFamilyPlan ? "family" : "individual";
       },
       methods: {
            handleCloseOnEscape(e) {
                if(e.key === "Escape" ) {
                    this.closeDialog();
                }
            },
            labelDialog(){
                let interval = setInterval(() => {
                    const dialog = document.getElementById("planTypeDetailDialog");
                    const element = document.getElementById("planTypeCardContainer");

                    if(element) {
                        let dialogDiv = element.parentElement.parentElement;
                        dialogDiv.setAttribute("aria-labelledby", "planTypeDetailTitle")
                        dialogDiv.firstChild.removeAttribute("tabindex")
                        clearInterval(interval);
                    }      
                }, 100)
            },
            toggleHideBackgroundContianer() {
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.setAttribute("aria-hidden","true");

                let elementsArray = document.querySelectorAll("body>:not(#app)");

                Array.from(elementsArray).forEach(node => {
                    node.setAttribute("aria-hidden","true");
                });
            },
            toggleShowBackgroundContianer() {
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.removeAttribute("aria-hidden");

                let elementsArray = document.querySelectorAll("body>:not(#app)");
                Array.from(elementsArray).forEach(node => {
                    node.removeAttribute("aria-hidden");
                });
            },
            handleTabOnModalLastElement(e) {
                if(e.shiftKey) return;
                if(!e.shiftKey && e.key === "Tab") e.preventDefault();
            },
            handleShiftTabOnModalFirstElement(e) {
                if(e.shiftKey && e.key === "Tab") {
                    e.preventDefault();
                }
            },
            closeDialog() {
                this.toggleShowBackgroundContianer();
                
                // this.focusAddPet();

                this.isDialogShow = false;
                this.$emit("closeDialog", false);
            },
            focusCloseButton() {
                const interval = setInterval(()=>{
                    if(this.$refs.planTypeCloseButton) {
                        this.$nextTick(()=>this.$refs.planTypeCloseButton.$el.focus());
                        clearInterval(interval);
                    }
                },50);
            },
            updatePlan() {
                if(this.selectedPlan === "family" && !this.isFamilyPlan) {
                    this.$emit("handleShowFamilyPlanClick");
                } 
                
                if(this.selectedPlan === "individual" && this.isFamilyPlan) {
                    this.$emit("handleShowIndividualPlanClick");
                }
            }
       }
   }
</script>
<style>
   .scroll-remove{
       overflow-x: hidden !important;
   }
</style>