<template>
    <div class="petComponent" v-if="petDetails">
        <v-row class="pet d-flex flex-nowrap flex-row"  role="region" aria-roledescription="card" :aria-label="(petDetails.species !== 1 ? 'Cat ' : 'Dog') + ':' + petDetails.name">
<!-- EDIT btn -->
            <v-col class="pr-0" cols="auto">
                <v-avatar class="v-avatar"> 
                        <span v-if="petDetails.species !== 1">
                            <span class="d-sr-only">{{'Cat:'+petDetails.name}}</span>
                            <!-- <inline-svg :id="petDetails.name" class="vector-svg cat" :src="challengerSnoopy1" height="68" width="68" role="img" aria-hidden="true"></inline-svg> -->
                            <!-- <v-icon  class="pet-icon cat" :id="petDetails.name" role="img" aria-hidden="true">$snoopy1</v-icon> -->
                            <v-img
                                alt="Pet Cat"
                                :src="require('@/assets/svg/icons-cat.svg')"
                                class="shrink"
                                contain
                                width="auto"
                                :max-width="$vuetify.breakpoint.smAndUp ? '69' : '50'"
                                :max-height="$vuetify.breakpoint.smAndUp ? '69' : '50'"
                            />
                        </span>
                        <span v-else>
                            <span class="d-sr-only">{{'Dog:'+petDetails.name}}</span>
                            <!-- <inline-svg :id="petDetails.name" class="vector-svg dog" :src="challengerSnoopy2" height="68" width="68" role="img" aria-hidden="true"></inline-svg> -->
                            <!-- <v-icon class="pet-icon dog"  :id="petDetails.name"  role="img" aria-hidden="true">$snoopy2</v-icon> -->
                            <v-img
                                alt="Pet Dog"
                                :src="require('@/assets/svg/icons-dog.svg')"
                                class="shrink"
                                contain
                                width="auto"
                                :max-width="$vuetify.breakpoint.smAndUp ? '69' : '50'"
                                :max-height="$vuetify.breakpoint.smAndUp ? '69' : '50'"
                            />
                        </span> 
                        <v-btn class="isEditable-btn challenger" :id="'editPencil' + petDetails.name" elevation="0" fab x-small color="primary" v-if="isEditable" @click.stop="showEditPetDialog(true)" :aria-label="'Edit Details for your ' + (petDetails.species !== 1 ? 'Cat ' : 'Dog ') + petDetails.name ">
                            <v-icon  dark> mdi-pencil </v-icon>
                        </v-btn>
                </v-avatar>
               
            </v-col>
            <v-col :cols="showingFamilyPlan ? '10':'8'" md="10" class="pet-detail-layout d-flex flex-grow-1 flex-column justify-center">
                <div class="d-flex flex-row flex-wrap align-start">
                    <span class="pet-name mr-3 text-truncate ml_darkgray--text" v-if="petDetails.name" :aria-label="'Your ' + petDetails.species !== 1 ? 'cat' : 'dog' + ', ' + petDetails.name">{{petDetails.name}}</span>
                    <v-btn class="btn-lower-case mb-2" color="ml_darkblue"  :fab="$vuetify.breakpoint.smAndDown" :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.mdAndUp" rounded outlined @click="deletePetDialogFormFinished('delete', petIndex)" v-if="pets.length > 1"> <v-icon v-show="$vuetify.breakpoint.smAndDown">mdi-trash-can-outline</v-icon> <span class="font-weight-medium body-1" v-show="$vuetify.breakpoint.mdAndUp">Remove</span></v-btn>
                </div>
                
                <ul class="pet-detail-list flex-row ml_darkergray--text">
                   

                    <li v-if="petDetails.species === 1">Dog</li>
                    <li v-else>Cat</li>

                    <li>{{getGender(petDetails.gender)}}</li>

                    <li v-if="petDetails.age > 1">{{petDetails.age}} years old</li>
                    <li v-else-if="petDetails.age === 1">{{petDetails.age}} year old</li>
                    <li v-else>Less than 1 year old</li>

                    <li v-if="petDetails.weight === null">{{getBreed(petDetails.breed,petDetails.species)}}</li>
                    <li v-else> Mixed Breed</li>
                    
                    <li v-if="petDetails.weight !== null">{{getWeight(petDetails.weight)}}</li>
                </ul>
            </v-col>
            <div>
                <editCreatePetDialog @closeDialog="showEditPetDialog"
                                    @hasFinished="AddPetPetDialogFormFinished"
                                    :dialog="valueDialog"
                                    :dialogHeadingText="'Edit ' + this.petDetails.name"
                                    :dialogOkButtonText="'Save Pet'" 
                                    :isEdit="true"
                                    :petIndex="petIndex"
                >
                </editCreatePetDialog>
            </div>
        </v-row>
    </div>
</template>
<script>
    import InlineSvg from 'vue-inline-svg';
    import editCreatePetDialog from '../shared/editCreatePetDialog.vue';
    import Swal from 'sweetalert2';
    export default {
        name: 'petview',
        components: {
            InlineSvg,
            editCreatePetDialog
        },
        props: {
            petDetails: {
                type: Object
            },
            petIndex: {
                type: Number
            },
            isEditable: false,
            showingFamilyPlan: false,
        },
        data() {
            return {
                valueDialog: false,
            }
        },
        computed: {
            
            breeds() {
                return this.$store.getters.breeds;
            },
            weights() {
                return this.$store.getters.weights;
            },
            colors() {
                return this.$store.getters.colors;
            },
            genders() {
                return this.$store.getters.genders;
            },
            species() {
                return this.$store.getters.species;
            },
            currentPetIndex() {
                return this.$store.getters.currentPetIndex;
            },
            pets() {
                return this.$store.getters.pets;
            },
            isFamilyPlan: function () {
                return this.$store.getters.isFamilyPlan;
            },
            partner: function() {
                return this.$store.getters.partner;
            },
            petsWithWellness() {
                return this.$store.getters.petsWithWellness;
            },
        },
        methods: {
            showEditPetDialog(status) {

                if (status) {
                    this.$store.commit("setCurrentPetIndex", this.petIndex);
                    this.$store.commit("updatePet", this.petDetails);
                } else {
                    if(this.currentPetIndex > 0) {
                        setTimeout(() => {
                            this.$store.commit("setCurrentPetIndex", 0);
                            this.$store.commit("updatePet", this.pets[0]);
                        }, 500);
                    } else {
                        this.$store.commit("setCurrentPetIndex", 0);
                        this.$store.commit("updatePet", this.pets[0]);
                    }
                    
                }

                this.valueDialog = status;
            },
            getBreed(breedId, speciesId) {
                let breedIndex = this.breeds[speciesId].findIndex(b => b.breedId === breedId);
                if(breedIndex !== -1) {  
                    return this.breeds[speciesId][breedIndex].name;
                } else {
                    return "";
                }
            },
            getGender(genderId) {
                let genderIndex = this.genders.findIndex(g => g.id === genderId);
                if(genderIndex !== -1) {  
                    return this.genders[genderIndex].name;
                } else {
                    return "";
                }
            },
            getColor(colorId) {
                let colorIndex = this.colors.findIndex(c => c.colorId === colorId);
                if(colorIndex !== -1) {  
                    return this.colors[colorIndex].name;
                } else {
                    return "";
                }
            },
            getWeight(weightId) {
                if (typeof weightId === "string") {
                    weightId = parseInt(weightId);
                }
                let weightIndex = this.weights.findIndex(w => w.id === weightId);
                return this.weights[weightIndex].label;
            },
            AddPetPetDialogFormFinished(status, petIndex) {
                this.$emit("AddPetPetDialogFormFinished", status, petIndex);
            },
            triggerDeletePetEvent() {
                let ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: 'Delete Pet'
                    }
                };
                
                digitalData.eventTrack('linkClick', ldo);
            },
            deletePetDialogFormFinished(status, petIndex){
                var self = this;
                let petBeingDeleted = petIndex;
                this.triggerDeletePetEvent();
                this.toggleShowBackgroundContianer();
            

                this.$nextTick(async () => {
                    await Swal.fire({
                        title: "Are you sure?",
                        text: "After deleting a pet you'll need to reselect your plans",
                        showConfirmButton: true,
                        showDenyButton: true, 
                        confirmButtonText: "Yes",
                        denyButtonText: "No",
                        allowOutsideClick: false,
                        focusConfirm: true,
                    }).then((result) => {
                        
                        if (result.isConfirmed) {
                            let petNameToFocus;
                            if(petBeingDeleted>0){
                                let petNameToFocusIndex = petBeingDeleted - 1;
                                petNameToFocus = this.pets[petNameToFocusIndex].name;
                            }
                            if(petBeingDeleted===0){
                                let petNameToFocusIndex = petBeingDeleted + 1;
                                petNameToFocus = this.pets[petNameToFocusIndex].name;
                            }
                            this.clearPlans();
                            this.pets.splice(petIndex, 1);

                            this.petsWithWellness.splice(petIndex, 1);
                            this.petsWithWellness.push(0);

                            if (this.pets.length === 1) {
                                this.$store.commit("setViewingFamilyPlan", false);
                                this.$store.commit("setIsFamilyPlan", false);
                            }

                            this.switchbackIndividual(this.offerFamilyPlan(this.pets, this.partner.isAgeRestrictionExempted));

                            this.focusAddPetAfterLoader();
                            this.$emit("AddPetPetDialogFormFinished", status, petIndex);
                        }
                    })
                });
            },
            toggleShowBackgroundContianer() {
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.removeAttribute("aria-hidden");

                let elementsArray = document.querySelectorAll("body>:not(#app)");
                Array.from(elementsArray).forEach(node => {
                    node.removeAttribute("aria-hidden");
                });
            },
            clearPlans() {
                this.$store.commit("setSelectedPolicies", []);
            },
            focusAddPetAfterLoader() {
                this.$store.commit("setDotLoader", true);
                this.$store.commit("setReannounceDotLoader", true);
                const interval = setInterval(() => {
                    if(document.getElementById('addPetBtn') && !this.$store.state.dotLoader) {
                        this.$nextTick(() => document.getElementById('addPetBtn').focus());
                        clearInterval(interval);
                    }
                }, 100);
            },
            
        }

    }
</script>